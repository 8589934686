import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import { isIp } from './shared/utils/get-context';

const routes: Routes = [
  {
    path: '', redirectTo: 'login', pathMatch: 'full'
  },
  {
    path: 'login', loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'first-access', loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'welcome', loadChildren: () => import('./modules/first-access-partner/first-access-partner.module').then(m => m.FirstAccessPartnerModule)
  },
  {
    path: 'users',
    loadChildren: () => import('./modules/users/users.module').then(m => m.UsersModule),
  },
  {
    path: 'reset/:key',
    loadChildren: () => import('./modules/reset-password/reset-password.module').then(m => m.ResetPasswordModule),
    pathMatch: 'full',
  },
  {
    path: 'unauthorized',
    loadChildren: () => {
      return import('./modules/unauthorized/unauthorized.module').then((m) => m.UnauthorizedModule);
    },
    pathMatch: 'full',
  },
  {
    path: 'transactions',
    loadChildren: () => {
      return isIp
      ? import('./modules/transactions-ip/transactions-ip.module').then((m) => m.TransactionsIpModule)
      : import('./modules/transactions/transactions.module').then((m) => m.TransactionsModule);
    },
  },
  {
    path: 'statements',
    loadChildren: () => {
      return import('./modules/statements-ip/statements-ip.module').then((m) => m.StatementsIpModule);
    },
  },
  {
    path: 'transactions',
    loadChildren: () => import('./modules/transactions/transactions.module').then(m => m.TransactionsModule),
  },
  {
    path: 'receivers',
    loadChildren: () => import('./modules/receivers/receivers.module').then(m => m.ReceiversModule),
  },
  {
    path: 'users',
    loadChildren: () => {
      return isIp
      ? import('./modules/users-ip/users-ip.module').then((m) => m.UsersIpModule)
      : import('./modules/users/users.module').then((m) => m.UsersModule)
    },
  },
  {
    path: 'entries',
    loadChildren: () => import('./modules/entries/entries.module').then(m => m.EntriesModule),
  },
  {
    path: 'integration',
    loadChildren: () => {
      return isIp
      ?import('./modules/integration-ip/integration-ip.module').then(m => m.SettingsIpModule)
      : import('./modules/integration/integration.module').then(m => m.SettingsModule);
    },
  },
  {
    path: 'configuration',
    loadChildren: () => import('./modules/configuration/configuration.module').then(m => m.ConfigurationModule),
  },
  {
    path: 'queries',
    loadChildren: () => import('./modules/queries/queries.module').then(m => m.QueriesModule),
  },
  {
    path: 'home',
    loadChildren: () => {
      return isIp
      ? import('./modules/home-ip/home-ip.module').then((m) => m.HomeIpModule)
      : import('./modules/home/home.module').then((m) => m.HomeModule);
    },
  },
  {
    path: 'heatmap',
    loadChildren: () => import('./modules/heatmap/heatmap.module').then(m => m.HeatmapModule),
  },
  {
    path: '*', redirectTo: 'home', pathMatch: 'full'
  },
  {
    path: '**', redirectTo: 'home', pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
